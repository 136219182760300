import React, { useState } from "react";
import classes from "./HamburgerMenu.module.css";
import CrossLogo from "../../assets/Cross_Icon.svg";
import Arrow from "../../assets/Navbar/Arrow.svg";
import Diamond from "../../assets/Navbar/Diamond.svg";
import { useNavigate } from "react-router-dom";
import Button1 from "../../assets/Buttons/Button 1.svg";
import axios from "axios";

const SHEET_URL =
  "https://sheet.best/api/sheets/1face7a6-62f0-4fe3-b39e-c5306e5cd80c";

const HamburgerMenu = ({ setmodal }) => {
  const [email, setEmail] = useState();
  const [clicked, setclicked] = useState(false);

  const navigate = useNavigate();
  const items = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "About",
      path: "/about",
    },
    {
      name: "Virtue",
      path: "/virtue",
    },
    {
      name: "Membership",
      path: "/membership",
    },
    {
      name: "Roadmap",
      path: "/roadmap",
    },
  ];

  const navigateTo = (path) => {
    setmodal(false);
    navigate(path);
  };

  const handleSubmit = async () => {
    if (email.length === 0) {
      return;
    }
    setclicked(true);
    try {
      await axios.post(SHEET_URL, { email });
      setEmail("");
    } catch (er) {
      console.log(er);
    }
  };

  return (
    <div className={classes.main}>
      <div onClick={() => setmodal(false)} className={classes.crossLogo}>
        <img src={CrossLogo} alt="CrossLogo" />
      </div>
      <div onClick={() => navigateTo("/members")} className={classes.btn}>
        <img src={Button1} alt="Button1" />
      </div>
      <div className={classes.itemsList}>
        {items.map((element, index) => (
          <div
            onClick={() => navigateTo(element.path)}
            key={index}
            className={classes.fromCenter}
          >
            {element.name}
          </div>
        ))}
      </div>
      <div className={classes.lastSection}>
        <div className={classes.heading}>RECEIVE CLUB UPDATES</div>
        <div className={classes.outer}>
          <div className={classes.inner}>
            <input
              onChange={(e) => setEmail(e.target.value)}
              placeholder="email@example.com"
              className={classes.input}
            />
            <img
              onClick={handleSubmit}
              className={clicked ? classes.ArrowAnimated : classes.Arrow}
              src={Arrow}
              alt="Slider"
            />
            <img
              className={clicked ? classes.DiamondAnimated : classes.Diamond}
              src={Diamond}
              alt="Slider"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HamburgerMenu;
