import React, { useState } from "react";
import BulletItem from "../../components/Bullet/BulletItem";
import classes from "./Virtue.module.css";
import BulletConnectors from "../../assets/Virtue/BulletConnectors.svg";
import AccessImg from "../../assets/Virtue/quotes/Access.png";
import AdventureImg from "../../assets/Virtue/quotes/Adventure.png";
import CommunityImg from "../../assets/Virtue/quotes/Community.png";
import CultureImg from "../../assets/Virtue/quotes/Culture.png";
import VentureImg from "../../assets/Virtue/quotes/Venture.png";
import VisionImg from "../../assets/Virtue/quotes/Vision.png";
import QuestImg from "../../assets/Virtue/quotes/Quest.png";
import Modal from "../../components/Modal/Modal";

const ITEMS = [
  "Access",
  "Adventure",
  "Community",
  "Culture",
  "Venture",
  "Vision",
  "Quest",
];

const QUOTES = {
  Access: AccessImg,
  Adventure: AdventureImg,
  Community: CommunityImg,
  Culture: CultureImg,
  Venture: VentureImg,
  Vision: VisionImg,
  Quest: QuestImg,
};

const Virtue = () => {
  const [modal, setmodal] = useState(false);
  const [activeItem, setActiveItem] = React.useState("Access");

  const openQuote = (item) => {
    console.log(item);
    setActiveItem(item);
    toggleModal();
  };

  const toggleModal = () => {
    setmodal(!modal);
  };

  return (
    <div className={classes.Container}>
      <div className={classes.LeftContainer}>
        <div className={classes.Titles}>
          <div className={classes.Header}>Paragons of Virtue</div>
          <div className={classes.SubHeader}>Virtue by</div>
        </div>
        <div className={classes.Space} />
        <div className={classes.BulletListContainer}>
          <BulletItem
            openQuote={openQuote}
            isActive={activeItem === ITEMS[0]}
            title={ITEMS[0]}
          />
          {ITEMS.slice(1).map((item) => (
            <React.Fragment key={item}>
              <img
                src={BulletConnectors}
                className={classes.BulletConnector}
                alt="connector"
                width={4}
                height={39}
              />
              <BulletItem
                openQuote={openQuote}
                isActive={activeItem === item}
                title={item}
              />
            </React.Fragment>
          ))}
          <img
            src={BulletConnectors}
            className={classes.BulletConnector}
            alt="connector"
            width={4}
            height={39}
          />
          <div className={classes.ScrollContainer}>
            <div className={classes.ScrollText}>SCROLL</div>
            <div className={classes.line}></div>
            <div className={classes.diamond}></div>
          </div>
        </div>
      </div>
      {modal && (
        <Modal modalClose={toggleModal}>
          <img className={classes.Quotes} src={QUOTES[activeItem]} alt="quote" />
        </Modal>
      )}
    </div>
  );
};

export default Virtue;
