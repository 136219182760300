import React, { useState } from "react";
import classes from "./Navigation.module.css";
import Black_Hamburger_Logo from "../../assets/Buttons/BlackHamburgerIcon.svg";
import Black_VS_Logo from "../../assets/Buttons/Black_VS Logo.svg";
import WhiteHamburger from "../../assets/Buttons/WhiteHamburger.svg";
import White_VS from "../../assets/Buttons/White_VS.svg";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";
import { Link } from "react-router-dom";

const Navigation = ({ displayMemberBtn = false, Button, color = "black" }) => {
  const [modal, setmodal] = useState(false);

  return (
    <div className={classes.main}>
      <div className={classes.clickable}>
        {color === "black" ? (
          <>
            <img
              onClick={() => {
                setmodal(true);
              }}
              src={Black_Hamburger_Logo}
              alt="hamburger icon"
            />
            <Link to="/">
              <img
                className={classes.vsLogo}
                src={Black_VS_Logo}
                alt="vs icon"
              />
            </Link>
          </>
        ) : (
          <>
            <img
              onClick={() => {
                setmodal(true);
              }}
              src={WhiteHamburger}
              alt="hamburger icon"
            />
            <Link to="/">
              <img className={classes.vsLogo} src={White_VS} alt="vs icon" />
            </Link>
          </>
        )}
      </div>
      <div
        onClick={() => {
          setmodal(true);
        }}
        className={classes.phoneNavBar}
      >
        {color === "black" ? (
          <Link to="/">
            <img className={classes.vsLogo} src={Black_VS_Logo} alt="vs icon" />
          </Link>
        ) : (
          <Link to="/">
            <img className={classes.vsLogo} src={White_VS} alt="vs icon" />
          </Link>
        )}
        {color === "black" ? (
          <img
            onClick={() => {
              setmodal(true);
            }}
            src={Black_Hamburger_Logo}
            alt="hamburger icon"
          />
        ) : (
          <img
            onClick={() => {
              setmodal(true);
            }}
            src={WhiteHamburger}
            alt="hamburger icon"
          />
        )}
      </div>
      {displayMemberBtn && (
        <Link to={"/members"} className={classes.membersArea}>
          <img src={Button} alt="RightArrow" />
        </Link>
      )}
      {modal && <HamburgerMenu setmodal={setmodal} />}
    </div>
  );
};

export default Navigation;
