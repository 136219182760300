import React from "react";
import classes from "./Roadmap.module.css";
import Cannes from "../../assets/Roadmap/Cannes.png";
import Iceland from "../../assets/Roadmap/Iceland.png";
import India from "../../assets/Roadmap/India.png";
import Japan from "../../assets/Roadmap/Japan.png";
import Grid from "../../assets/Roadmap/Grid.png";

const Roadmap = () => {
  return (
    <div className={classes.Container}>
      <div className={classes.main}>
        <div className={classes.Heading}>Roadmap</div>
        <div className={classes.Description}>
          We strive to under-promise and over deliver. Our goal is to create
          experiences that have a lasting impact on members. We take a 2 pronged
          approach to member delight; digtal & in person experiences. The roadmap
          below highlights the key places and events you can look forward to!
        </div>
        <div className={classes.grid}>
          <img src={Grid} alt="Grid" />
        </div>
      </div>
      <div className={classes.phoneGrid}>
        <img src={Cannes} alt="Cannes" />
        <img src={Iceland} alt="Iceland" />
        <img src={India} alt="India" />
        <img src={Japan} alt="Japan" />
      </div>
    </div>
  );
};

export default Roadmap;
