import React, { useEffect, useState } from "react";
import classes from "./Membership.module.css";
import Modal from "../../components/Modal/Modal";
import SubmitLogo from "../../assets/Membership/Submitted.svg";
import axios from "axios";
import DatePicker from "react-date-picker";

const SHEET_URL =
  "https://sheet.best/api/sheets/f41048b9-6309-4b4f-b411-5111c89f7827";

const Membership = () => {
  const [modal, setmodal] = useState(false);
  const [values, setvalues] = useState({
    name: "",
    email: "",
    date: new Date(),
    statementOfPurpose: "",
  });

  useEffect(() => {
    document.getElementById("date").focus();
  }, []);

  const onChange = (e) => {
    const temp = { ...values };
    temp[e.target.name] = e.target.value;
    setvalues(temp);
  };

  const onSubmit = async (e) => {
    if (e) e.preventDefault();
    try {
      setmodal(true);
      console.log("Button Pressed");
      const response = await axios.post(SHEET_URL, values);
      setvalues({
        name: "",
        email: "",
        date: new Date(),
        statementOfPurpose: "",
      });
      setTimeout(() => {
        setmodal(false);
      }, 3000);
      console.log({ response });
    } catch (err) {
      console.log(err);
    }
  };

  const modalClose = () => {
    setmodal(false);
  };

  return (
    <div className={classes.Container}>
      <div className={classes.Heading}>Membership</div>
      <form onSubmit={onSubmit}>
        <div className={classes.form}>
          <div className={classes.inputBox}>
            <label>Name</label>
            <br />
            <input
              name="name"
              value={values.name}
              onChange={onChange}
              className={classes.input}
              placeholder="John Smith"
            />
          </div>
          <div className={classes.inputBox}>
            <label>Date Of Birth</label>
            <br />
            <input
              onChange={onChange}
              name="date"
              id="date"
              type="date"
              value={values.date}
              className={classes.input}
              style={{ textTransform: "uppercase", opacity: "0.6" }}
              placeholder="MM/DD/YYYY"
            />
          </div>
          <div className={classes.inputBox}>
            <label>Email</label>
            <br />
            <input
              onChange={onChange}
              name="email"
              type="email"
              value={values.email}
              className={classes.input}
              placeholder="johnsmith@gmail.com"
            />
          </div>
          <div className={classes.inputBox}>
            <label>Statement of Purpose</label>
            <br />
            <textarea
              name="statementOfPurpose"
              onChange={onChange}
              value={values.statementOfPurpose}
              className={classes.textarea}
              placeholder="tell us about yourself and why you would be a good fit for the VS club."
            />
          </div>
          <div className={classes.submitBtn}>
            <button type="submit">Submit</button>
          </div>
        </div>
      </form>

      {modal && (
        <Modal modalClose={modalClose}>
          <div className={classes.ModalContainer}>
            <img src={SubmitLogo} alt="SubmitLogo" />
            <div className={classes.Text}>
              Thank you for your submission! Our membership team will contact
              you to schedule an interview.
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Membership;
