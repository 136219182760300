import React from "react";

import classes from "./BulletItem.module.css";

function BulletItem({ openQuote, isActive, title }) {
  return (
    <div className={classes.BulletItem}>
      <div
        className={
          isActive ? classes.BulletOuterActive : classes.BulletOuterInActive
        }
      >
        <div
          className={
            isActive ? classes.BulletInnerActive : classes.BulletInnerInActive
          }
        />
      </div>
      <div
        onClick={() => openQuote(title)}
        className={
          isActive
            ? classes.BulletItemTextActive
            : classes.BulletItemTextInActive
        }
      >
        {title}
      </div>
    </div>
  );
}

export default BulletItem;
