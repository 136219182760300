import React from "react";
import classes from "./About.module.css";
import AboutImage from "../../assets/About/AboutImage.svg";
import Button2 from "../../assets/Buttons/Button 2.svg";

const About = () => {
  return (
    <div className={classes.main}>
      <div className={classes.leftSide}>
        <div className={classes.heading}>About</div>
        <div className={classes.quote}>
          “There is no power for change greater than a community discovering
          what it cares about”
        </div>
        <div className={classes.description}>
          <span>VS is a members only</span> collective of trailblazers, leaders
          and visionaries united by virtue, impact and collaboration
        </div>
      </div>
      <div className={classes.rightSide}>
        <img src={AboutImage} alt="AboutImage" />
      </div>
      <div className={classes.description2}>
        <span style={{ fontWeight: "bold" }}>VS is a members only</span>{" "}
        collective of trailblazers, leaders and visionaries united by virtue,
        impact and collaboration
      </div>
      <div className={classes.btn}>
        <img src={Button2} alt="Button2" />
      </div>
    </div>
  );
};

export default About;
