import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./HOC/Layout";
import About from "./pages/About/About";
import Home from "./pages/Home/Home";
import LandingPage from "./pages/Landing Page/LandingPage";
import MembersArea from "./pages/MembersArea/MembersArea";
import Membership from "./pages/Membership/Membership";
import Roadmap from "./pages/Roadmap/Roadmap";
import Virtue from "./pages/Virtue/Virtue";
import Button2 from "./assets/Buttons/Button 2.svg";
import Button3 from "./assets/Buttons/Button 3.svg";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Layout Component={Home} />} /> */}
        <Route
          path="/"
          element={
            <Layout
              Component={LandingPage}
              Button={Button3}
              displayMemberBtn={true}
            />
          }
        />
        <Route
          path="/about"
          element={
            <Layout
              Button={Button2}
              Component={About}
              displayMemberBtn={true}
            />
          }
        />
        <Route
          path="/virtue"
          element={
            <Layout
              Button={Button3}
              Component={Virtue}
              displayMemberBtn={true}
              color={"white"}
            />
          }
        />
        <Route
          path="/members"
          element={<Layout Component={MembersArea} displayMemberBtn={false} />}
        />
        <Route
          path="/membership"
          element={
            <Layout
              Component={Membership}
              displayMemberBtn={false}
              color={"white"}
            />
          }
        />
        <Route
          path="/roadmap"
          element={
            <Layout
              Button={Button3}
              color={"white"}
              Component={Roadmap}
              displayMemberBtn={true}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
