import React from "react";
import classes from "./LandingPage.module.css";
import Button3 from "../../assets/Buttons/Button 3.svg";
import { Link } from "react-router-dom";

const LandingPage = () => {
  return (
    <div className={classes.main}>
      <div className={classes.heading1}>BIENVENUE</div>
      <div className={classes.heading2}>AU</div>
      <div className={classes.heading3}>Club VS</div>
      <div className={classes.heading4}>Where vision meets venture.</div>
      <Link to="/members" className={classes.btn}>
        <img src={Button3} alt="Button3" />
      </Link>
    </div>
  );
};

export default LandingPage;
