import React from "react";

import classes from "./Modal.module.css";

export default function Modal({ children, modalClose }) {
  return (
    <div className={classes.ModalContainer} onClick={modalClose}>
      {children}
    </div>
  );
}
