import React from "react";
import classes from "./MembersArea.module.css";

const MembersArea = () => {
  return (
    <div className={classes.main}>
      <div className={classes.heading}>Patience is a VIRTUE</div>
    </div>
  );
};

export default MembersArea;
