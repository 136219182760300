import React from "react";
import Navigation from "../components/Navigation/Navigation";
import classes from "./Layout.module.css";

const Layout = ({ Component, displayMemberBtn, Button, color }) => {
  return (
    <div className={classes.main}>
      <div className={classes.navigator}>
        <Navigation
          Button={Button}
          color={color}
          displayMemberBtn={displayMemberBtn}
        />
      </div>
      <Component />
    </div>
  );
};

export default Layout;
